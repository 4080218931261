/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic";

const LandingPage = lazy(() => import("../pages/landing-page/LandingPage"));
const Onboarding = lazy(() => import("../pages/onboarding/Onboarding"));

export const Routes = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter>
                <Switch>
                    <Redirect exact from="/" to="/lp" />

                    <Route path="/lp/:slug?" component={LandingPage} />
                    <Route path="/onboarding/:id?" component={Onboarding} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};
